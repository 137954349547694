import { PencilSimpleLine, Trash } from '@phosphor-icons/react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import React, { useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import { useTranslations } from '@/hooks/useTranslations'
import { deleteKanbanColumn, updateKanbanColumn } from '@/models/Kanban'
import type {
  IAddKanbanColumn,
  IKanbanColumn,
  IKanbanColumnCard,
} from '@/types/roadmap'
import toaster from '@/utils/toast'

const KanbanNewEntryDialog = dynamic(import('./KanbanNewEntryDialog'), {
  ssr: false,
})

interface IPropTypes {
  title?: string | undefined
  id?: string
  updateTitle?: (title: string) => void
  // eventBus
  onAdd: (laneId: string, item: IKanbanColumnCard) => void
  readonly?: boolean
  onDelete?: (laneId: string) => void
}
export default function KanbanLaneHeader({
  title,
  id,
  // eventBus,
  updateTitle,
  readonly,
  onDelete,
  onAdd,
}: IPropTypes) {
  const [editing, setEditing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState<IAddKanbanColumn>({
    name: title || '',
  })
  const t = useTranslations()

  useEffect(
    () => () => {
      setEditing(false)
    },
    []
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formData.name.length || !id) return null
    setSubmitting(true)
    return updateKanbanColumn(id, formData)
      .then((data: IKanbanColumn) => {
        if (updateTitle) updateTitle(data.title as string)
      })
      .then(() => {
        setEditing(false)
      })
      .catch(toaster.error)
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleKanbanDelete = () => {
    if (!id) return null
    return deleteKanbanColumn(id)
      .then(() => onDelete?.(id))
      .catch(toaster.error)
  }

  const renderHeader = () => {
    if (editing && !readonly) {
      return (
        <div className='mx-1 flex items-center justify-between px-2 pb-0 pt-1'>
          <form
            onSubmit={handleSubmit}
            className='flex w-full flex-col items-center justify-center space-x-1 text-sm text-gray11 '
          >
            <Input
              type='text'
              placeholder={t('roadmap.kanban.addLane.placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, name: e.target.value })
              }}
              autoFocus
              required
              defaultValue={title}
            />
            <div className='mt-2 flex w-full items-center justify-end space-x-2'>
              <Button
                type='button'
                variant='naked'
                size='xxs'
                onClick={() => setEditing(false)}
              >
                {t('buttons.cancel')}
              </Button>
              <Button size='xxs' type='submit' disabled={submitting}>
                {submitting ? t('buttons.submitting') : t('buttons.submit')}
              </Button>
            </div>
          </form>
        </div>
      )
    }
    return (
      <div className={clsx('mx-1 flex items-center justify-between p-2')}>
        <p className='truncate text-sm font-semibold text-gray12 '>{title}</p>
        {!readonly ? (
          <div className='flex items-center space-x-1'>
            <AlertDialog
              type='danger'
              confirmText={t('buttons.delete')}
              title={t('roadmap.kanban.deleteLane.alertTexts.title')}
              description={t(
                'roadmap.kanban.deleteLane.alertTexts.description'
              )}
              onConfirm={handleKanbanDelete}
            >
              <Button
                variant='secondary'
                fab
                size='xs'
                icon={
                  <Trash weight='bold' className='flex h-3.5 w-3.5 shrink-0' />
                }
              />
            </AlertDialog>
            <Button
              variant='secondary'
              fab
              size='xs'
              onClick={() => setEditing(true)}
              icon={
                <PencilSimpleLine
                  weight='bold'
                  className='flex h-3.5 w-3.5 shrink-0'
                />
              }
            />
            <KanbanNewEntryDialog
              lane={{
                id: id as string,
                title,
                cards: [],
              }}
              onAdd={onAdd}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return <>{renderHeader()}</>
}
