import clsx from 'clsx'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import type { DropResult } from 'react-beautiful-dnd'

import KanbanLaneHeader from '@/components/roadmap/kanban/KanbanLaneHeader'
import KanbanNewLaneForm from '@/components/roadmap/kanban/KanbanNewLaneForm'
import KanbanRoadmapItem from '@/components/roadmap/KanbanRoadmapItem'
import Kanban from '@/components/shared/components/kanban/Kanban'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getKanbanColumnEntryCards,
  getKanbanColumns,
  updateKanbanEntry,
} from '@/models/Kanban'
import type {
  IKanbanColumn,
  IKanbanColumnCard,
  IKanbanItem,
} from '@/types/roadmap'
import toaster from '@/utils/toast'

interface IPropTypes {
  readonly?: boolean
}
export default function KanbanRoadmapPage({ readonly }: IPropTypes) {
  const t = useTranslations('roadmap')

  const { isAdmin } = useContext(HNContext)

  const [cards, setCards] = useState<IKanbanColumnCard[]>([])
  const [columns, setColumns] = useState<IKanbanColumn[]>([])

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchData = () => {
    getKanbanColumns()
      .then((data) => {
        setColumns(data)
        return data.map((datum: IKanbanColumn) =>
          getKanbanColumnEntryCards(datum.id, { page: 1 }).then((newCards) => {
            setCards((prevCards) => [
              ...prevCards,
              ...newCards.map((card: IKanbanColumnCard) => ({
                laneId: card.kanban_id,
                ...card,
              })),
            ])
          })
        )
      })
      .then((entryPromises) => Promise.all(entryPromises))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  const items = useMemo(
    () =>
      cards
        .map((card) => ({
          ...card,
          laneId: card.laneId || '',
          index: card.position,
          title: `${card.title}`,
        }))
        .sort((a, b) => a.index - b.index),
    [cards]
  )

  const lanes = useMemo(
    () =>
      columns.map((column, index) => ({
        ...column,
        name: column.title || '',
        title: column.title || '',
        index,
      })),
    [columns]
  )

  const handleAddItem = (_laneId: string, item: IKanbanColumnCard) => {
    setCards((prevCards) => [
      ...prevCards,
      { ...item, laneId: item.kanban_id.toString() },
    ])
  }

  const handleAddLane = (lane: IKanbanColumn) => {
    setColumns((prevColumns) => [...prevColumns, lane])
  }

  const handleLaneDelete = (laneId: string) => {
    setColumns((prevColumns) =>
      prevColumns.filter((lane) => lane.id !== laneId)
    )
  }
  // const handleUpdate = (item: IKanbanColumnCard) => {
  //   setCards((prevCards) =>
  //     prevCards.map((prevCard) =>
  //       prevCard.id === item.id ? { ...prevCard, ...item } : prevCard
  //     )
  //   )
  // }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return
    const item = cards.find((itm) => +itm.id === +result.draggableId)

    if (!item) return

    const oldCards = [...cards]
    const newCards = [...cards].map((prevCard) =>
      prevCard.id === item.id
        ? {
            ...prevCard,
            ...item,
            position: result.destination?.index || 0,
            laneId: result.destination?.droppableId?.toString(),
          }
        : {
            ...prevCard,
            position:
              prevCard.laneId?.toString() ===
                result.destination?.droppableId?.toString() &&
              prevCard.position >= (result.destination?.index || 0)
                ? prevCard.position + 1
                : prevCard.position,
          }
    )

    setCards(newCards)
    updateKanbanEntry(result.draggableId, {
      position: result.destination.index,
      kanban_id: result.destination.droppableId,
    })
      .then(() => {
        setCards(newCards)
      })
      .catch((err) => {
        toaster.error(err.message)
        setCards(oldCards)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (loading) return <Spinner isScreen />
  if (errorMessage) return <EmptyState description={errorMessage} />

  return (
    <div
      style={{
        display: readonly ? 'block' : 'flex',
        textAlign: readonly ? 'center' : 'left',
      }}
      className='overflow-x-auto'
    >
      <Kanban
        readonly={readonly}
        items={items}
        lanes={lanes}
        laneHeaderRenderer={(lane) => {
          return (
            <KanbanLaneHeader
              {...lane}
              readonly={readonly}
              onAdd={handleAddItem}
              onDelete={handleLaneDelete}
            />
          )
        }}
        onDragEnd={handleDragEnd}
        itemRenderer={(kanbanItem) => {
          return (
            <KanbanRoadmapItem
              readonly={readonly}
              // @ts-ignore
              kanbanItem={kanbanItem as IKanbanItem}
            />
          )
        }}
        emptyListRenderer={() => {
          return <EmptyState title={t('messages.noPosts.title')} />
        }}
        addLaneRenderer={() =>
          isAdmin ? <KanbanNewLaneForm onAdd={handleAddLane} /> : <></>
        }
        className={clsx(
          isAdmin
            ? 'mx-auto !max-h-[calc(100vh-54px)] max-w-max'
            : 'mx-auto !max-h-[calc(100vh-54px)] max-w-max'
        )}
        classNames={{
          lane: {
            laneContainer: isAdmin
              ? 'max-h-[calc(100vh-140px)] min-h-[calc(100vh-140px)] '
              : 'pb-12 max-h-[calc(100vh-160px)] min-h-[calc(100vh-160px)] ',
          },
        }}
      />
    </div>
  )
}
