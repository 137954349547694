import React from 'react'

import CustomRoadmapItemLink from '@/components/roadmap/kanban/CustomRoadmapItemLink'
import type { IKanbanItem } from '@/types/roadmap'

interface IPropTypes {
  kanbanItem: IKanbanItem
  readonly?: boolean
}
export default function KanbanRoadmapItem({ kanbanItem }: IPropTypes) {
  return (
    <CustomRoadmapItemLink item={kanbanItem}>
      <div className='my-1 space-y-2 rounded-md border border-gray4 bg-snow p-2 transition duration-200 hover:border-gray5  '>
        {kanbanItem.header_image && (
          <img
            alt={kanbanItem.title}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            className='h-40 w-full rounded object-none'
            src={kanbanItem.header_image.toString()}
          />
        )}
        <span className='flex justify-start whitespace-pre-wrap text-left text-[13px] font-medium '>
          {kanbanItem.title}
        </span>
        {kanbanItem.two_liner && (
          <span className='mb-0 flex justify-start whitespace-pre-wrap text-left text-sm text-gray11'>
            {kanbanItem.two_liner}
          </span>
        )}
      </div>
    </CustomRoadmapItemLink>
  )
}
