import {
  ADMIN_KANBAN_COLUMN_ENTRIES_PATH,
  ADMIN_KANBAN_COLUMN_PATH,
  ADMIN_KANBAN_DELETE_TAGGED_POST_PATH,
  ADMIN_KANBAN_ENTRY_PATH,
  ADMIN_KANBAN_PATH,
  ADMIN_KANBAN_TAG_NEW_POST_TO_ENTRY_PATH,
  KANBAN_COLUMN_ENTRIES_PATH,
  KANBAN_COLUMN_ENTRY,
  KANBAN_PATH,
  KANBAN_TOP_POSTS,
} from '@/config/routes'
import API from '@/lib/API'
import type { IPost } from '@/types/post'
import type { IAddKanbanColumn, IKanbanListAPIParams } from '@/types/roadmap'

export const getKanbanColumns = () => API.get(KANBAN_PATH).then((data) => data)

export const getKanbanColumnEntryCards = (
  entryId: string,
  params: IKanbanListAPIParams
) => API.get(KANBAN_COLUMN_ENTRIES_PATH(entryId), params).then((data) => data)

export const createKanbanColumn = (data: IAddKanbanColumn) => {
  return API.post(ADMIN_KANBAN_PATH, data).then((result) => result.kanban)
}

// TODO Any to be removed
export const updateKanbanColumn = (id: string, data: any) => {
  return API.put(ADMIN_KANBAN_COLUMN_PATH(id), data).then(
    (result) => result.kanban
  )
}
export const deleteKanbanColumn = (id: string) => {
  return API.delete(ADMIN_KANBAN_COLUMN_PATH(id))
}

export const updateKanbanEntry = (entryId: string, data: any) =>
  API.put(ADMIN_KANBAN_ENTRY_PATH(entryId), data).then(
    (response) => response.kanban_entry
  )

export const addKanbanEntry = (laneId: string, data: FormData) =>
  API.postWithFiles(ADMIN_KANBAN_COLUMN_ENTRIES_PATH(laneId), data).then(
    (response) => response.kanban_entry
  )
export const getKanbanEntry = (id: string) =>
  API.get(KANBAN_COLUMN_ENTRY(id)).then((response) => ({
    ...response.kanban_entry,
    posts: response.feature_requests,
  }))
export const getAdminKanbanEntry = (id: string) =>
  API.get(ADMIN_KANBAN_ENTRY_PATH(id)).then((response) => ({
    ...response.kanban_entry,
    posts: response.feature_requests,
  }))

export const getKanbanTopPosts = () =>
  API.get(KANBAN_TOP_POSTS).then((data) => data.top_feature_requests)

export const deleteKanbanEntry = (id: string) =>
  API.delete(ADMIN_KANBAN_ENTRY_PATH(id))

export const addPostToKanbanEntry = (
  id: string,
  data: { feature_request_id: string }
): Promise<IPost> => API.post(ADMIN_KANBAN_TAG_NEW_POST_TO_ENTRY_PATH(id), data)
export const removePostFromKanbanEntry = (
  id: string,
  data: { kanban_entry_id: string }
) => API.delete(ADMIN_KANBAN_DELETE_TAGGED_POST_PATH(id), data)
