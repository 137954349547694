import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'

interface IPropTypes {
  item: {
    id?: string
  }
  children: React.ReactNode
  className?: string
  newTabOpen?: boolean
}
const KEYS_TO_PUSH = ['name', 'id', 'boardSlug']
const MANDATORY_KEYS_TO_PUSH = [
  'postModerationStatus',
  'postStatusPage',
  'singleBoardTabs',
  'commentModerationTab',
  'roadmapTab',
]
export default function CustomRoadmapItemLink({
  item,
  children,
  className = '',
  newTabOpen = false,
  ...rest
}: IPropTypes) {
  const router = useRouter()
  const { isAdmin } = useContext(HNContext)

  const getHREF = () => {
    const keys = [`roadmapId=${item.id}`]
    Object.keys(router.query).forEach((key) => {
      if (KEYS_TO_PUSH.includes(key)) keys.push(`${key}=${router.query[key]}`)
    })

    return `?${[
      ...keys,
      ...MANDATORY_KEYS_TO_PUSH.map((key) => `${key}=''`),
    ].join('&')}`
  }

  const classNames = clsx('hover:no-underline', className)
  return (
    <UnstyledLink
      href={getHREF()}
      as={isAdmin ? `/admin/r/p/${item.id}` : `/roadmap/${item.id}`}
      className={classNames}
      target={newTabOpen ? '_blank' : undefined}
      {...rest}
    >
      {children}
    </UnstyledLink>
  )
}
