import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import { useTranslations } from '@/hooks/useTranslations'
import { createKanbanColumn } from '@/models/Kanban'
import type { IAddKanbanColumn, IKanbanColumn } from '@/types/roadmap'
import toaster from '@/utils/toast'
// import Context from '@/contexts/Context'
// import { createKanbanColumn } from '@/models/Roadmap'

interface IProps {
  onAdd: (lane: IKanbanColumn) => void
}
export default function KanbanNewLaneForm(props: IProps) {
  const { onAdd } = props
  const [submitting, setSubmitting] = useState(false)
  const [editing, setEditing] = useState(false)
  const [formData, setFormData] = useState<IAddKanbanColumn>({
    name: '',
  })
  const t = useTranslations()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formData.name.length) return null
    setSubmitting(true)
    return createKanbanColumn(formData)
      .then(onAdd)
      .then(() => {
        setFormData({ name: '' })
        setEditing(false)
      })
      .catch(toaster.error)
      .finally(() => setSubmitting(false))
  }

  if (!editing)
    return (
      <p
        className='my-2 flex w-72 cursor-pointer flex-col items-center justify-center space-x-1 rounded border-0 bg-gray3 p-4 text-sm text-gray11 transition  '
        onClick={() => setEditing(!editing)}
      >
        {t('roadmap.kanban.addLane.buttonText')}
      </p>
    )
  return (
    <form
      onSubmit={handleSubmit}
      className='m-1 flex w-72 flex-col items-center justify-center space-x-1 rounded border-0 bg-gray3 p-4 text-sm text-gray11 transition  '
    >
      <Input
        type='text'
        placeholder={t('roadmap.kanban.addLane.placeholder')}
        value={formData.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFormData({ ...formData, name: e.target.value })
        }}
        autoFocus
        required
      />
      <div className='mt-2 w-full space-x-1'>
        <Button size='sm' type='submit' disabled={submitting}>
          {t('roadmap.kanban.addLane.title')}
        </Button>
        <Button
          type='button'
          variant='text'
          size='sm'
          disabled={submitting}
          onClick={() => setEditing(!editing)}
        >
          {t('buttons.cancel')}
        </Button>
      </div>
    </form>
  )
}
